<template>
    <div>
        <el-form ref="form" :model="formData" :rules="rules" label-width="94px" @submit.native.prevent>
            <el-form-item label="用户名" prop="userName">
                <el-input v-model="formData.userName" placeholder="请输入用户名" clearable></el-input>
            </el-form-item>
            <div style="text-align: center">
                <el-button type="primary" class="submit-btn" @click="handleSubmit">确定</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "UpdateUserName",
    data(){
        return{
            rules: {
                userName: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: ['blur', 'change']
                    }
                ],
            },
            form:{}
        }
    },
    props: {
        formData:{
            type:Object
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        init(){
            this.form = this.formData;
        },
       /**
        * @Description 提交
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 邵澳
        * @Date 2023/08/28 15:07
        **/
        handleSubmit() {
            this.$refs.form.validate((val) => {
                if (val) {
                   this.$emit('handleSubmit',this.form);
                   this.$emit('handleClose');
                }
            })
        },
        /**
        * @Description 关闭弹窗
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 韩熙昊
        * @Date 2022/12/14 15:07
        **/
        handleClose() {
            this.$refs.form.clearValidate()
            this.$emit('handleClose')
        },

    }
}
</script>
<style lang="scss">
    .submit-btn{
        width: 300px;
        height: 36px;
        line-height: 1.3;
    }
</style>
