<template>
    <div>
        <div class="tabs-box">
            <div :class="['buttons',currentIndex == 0 ? 'current' : '']" @click="changeTab(0)">
                <div>通过密码修改</div>
                <div class="bottom-line"></div>
            </div>
            <div class="line"></div>
            <div :class="['buttons',currentIndex == 1 ? 'current' : '']" @click="changeTab(1)">
                <div>通过手机号修改</div>
                <div class="bottom-line"></div>
            </div>
        </div>
        <div class="form-box" v-if="currentIndex == 0">
            <el-form ref="pwdForm" :model="passForm" :rules="passRules" label-width="94px">
                <el-form-item label="原登录密码" prop="oldPassword">
                    <el-input 
                        v-model="passForm.oldPassword" 
                        placeholder="请输入原登录密码" 
                        :type="showPassword ? 'text' : 'password'" 
                    >
                        <i slot="suffix" @click="switchPassword">
                            <img
                                v-if="showPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                </el-form-item>
                 <el-form-item label="设置新密码" prop="newPassword">
                    <el-input 
                        v-model="passForm.newPassword" 
                        placeholder="请输入新密码" 
                        :type="showNewPassword ? 'text' : 'password'" 
                    >
                        <i slot="suffix" @click="switchNewPassword">
                            <img
                                v-if="showNewPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                    <div class="tips">1.密码必须包含大小写字母、数字和特殊符号(@$!%*?.)</div>
                    <div class="tips">2.密码长度不小于8位</div>
                </el-form-item>
                 <el-form-item label="确认密码" prop="affirmPassword">
                    <el-input 
                        v-model="passForm.affirmPassword" 
                        placeholder="请输入原登录密码" 
                        :type="showConfirmPassword ? 'text' : 'password'"
                    >
                        <i slot="suffix" @click="switchConfirmPassword">
                            <img
                                v-if="showConfirmPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                </el-form-item>
                <div style="text-align: center">
                    <el-button type="primary" class="submit-btn" :disabled="disableSubmit" @click="handleSubmit">确定</el-button>
                </div>
            </el-form>
        </div>
        <div class="form-box" v-if="currentIndex == 1">
            <el-form v-if="phone" ref="phoneForm" :model="phoneForm" :rules="phoneRules" label-width="94px">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="phoneForm.phone" placeholder="请输入手机号" readonly></el-input>
                </el-form-item>
                <!-- <el-form-item prop="numCode" label='数字验证码'>
                    <div class="codeItem">
                        <el-input style="width:344px;" v-model="phoneForm.numCode" placeholder="请输入数字验证码" @keyup.enter.native="handleSubmitBindPhone"></el-input>
                        <img :src="codeUrl" class="form-code-img" @click="referhCode" alt="" />
                    </div>
                </el-form-item> -->
                 <el-form-item label="验证码" prop="code">
                    <div class="send-code-box">
                        <el-input v-model="phoneForm.code" placeholder="请输入短信验证码" :class="classes">
                            <span slot="append" class="send-code-btn" v-if="changeTimer === null" @click="useVerify">发送验证码</span>
                            <span slot="append" class="send-code-btn send-code-btn-disabeld" v-else>{{ changeCount }}秒后重新获取</span>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="设置新密码" prop="newPassword">
                    <el-input 
                        v-model="phoneForm.newPassword" 
                        placeholder="请输入新密码" 
                        :type="showPhoneNewPassword ? 'text' : 'password'"
                    >
                        <i slot="suffix" @click="switchPhoneNewPassword">
                            <img
                                v-if="showPhoneNewPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                    <div class="tips">1.密码必须包含大小写字母、数字和特殊符号(@$!%*?.)</div>
                    <div class="tips">2.密码长度不小于8位</div>
                </el-form-item>
                <el-form-item label="确认密码" prop="affirmPassword">
                    <el-input 
                        v-model="phoneForm.affirmPassword" 
                        placeholder="请再次输入新密码" 
                        :type="showPhoneConfirmPassword ? 'text' : 'password'"
                    >
                        <i slot="suffix" @click="switchPhoneConfirmPassword">
                            <img
                                v-if="showPhoneConfirmPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                </el-form-item>
                <div style="text-align: center">
                    <el-button type="primary" class="submit-btn" :disabled="disableSubmit" @click="handleSubmit">确定</el-button>
                </div>
            </el-form>
            <el-empty v-else description="请先绑定手机号"></el-empty>
        </div>
        <Verify
            @success="success"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>
    </div>
</template>
<script>
import { Encrypt } from '@/libs/secret'
import { mapState } from "vuex";
import { serviceDownload } from "@/services/index.js";
import Verify from "../../verifition/Verify";
export default {
    name: "UpdatePassword",
    components: {
		Verify
	},
    props: {
        loginPageInfo: {
            type: Object
        },
    },
    data(){
        const reg = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/
        let checkPassword = (rule, value, callback) => {
            if (!value || !reg.test(value)) {
                return callback(new Error('密码必须包含大小写字母、数字和特殊符号(@$!%*?.)，且密码长度不小于8位'));
            }
            if(this.currentIndex == 0 && this.passForm.affirmPassword){
                this.$refs.pwdForm.validateField("affirmPassword");
            }else if(this.currentIndex == 1 && this.phoneForm.affirmPassword){
                this.$refs.phoneForm.validateField("affirmPassword");
            }
            if(this.oldPwdStatus == '10017'){
                return callback(new Error("新密码不能与旧密码一致"))
            }
            return callback()
        };
        let affirmPassword = (rule, value, callback) => {
            let password = '';
            let confirmPwd = '';
            if(this.currentIndex == 0){//通过密码
                password = this.passForm.newPassword;
                confirmPwd = this.passForm.affirmPassword;
            }else{//通过手机号
                password = this.phoneForm.newPassword;
                confirmPwd = this.phoneForm.affirmPassword;
            }
            if (confirmPwd && confirmPwd != password) {//确认密码输入框有值并且和新密码不相等
                console.log('校验确认密码2');
                return callback(new Error('两次密码输入不一致'));
            }
            return callback()
        };
        const checkPhone = (rule, value, callback) => {
            console.log('校验手机号');
            const reg = /^1[3-9]\d{9}$/;
            if (value == null || String(value).trim() === "") {
                callback(new Error("请输入手机号"));
            } else if (!reg.test(value)) {
                callback(new Error("手机号格式不正确"));
            } else {
                callback();
            }
        }
        const checkOldPwd = (rule, value, callback) => {
            switch(this.oldPwdStatus){
                case "200":
                    return callback();
                    break;
                case "10010":
                    return callback(new Error("密码错误"))
                    break;
                case "10017":
                    this.$refs.pwdForm.validateField("newPassword");
                    break;
            }
        }
        const numCode = (rule, value, callback) => {
            if(value == ''){
                return callback(new Error("请输入数字验证码"))
            }else{
                return callback();
            }
        }
        return{
            currentIndex:0,
            passForm:{
                oldPassword: '',
                newPassword: '',
                affirmPassword: ''
            },
            phoneForm:{
                phone:'',
                code:"",
                newPassword:"",
                affirmPassword:'',
                numCode:'',
                captchaVerification: ''
            },
            passRules: {
                newPassword: [
                    {
                        required: true,
                        validator: checkPassword,
                        trigger: ['blur', 'change'],
                    }
                ],
                affirmPassword: [
                    {
                        required: true,
                        validator: affirmPassword,
                        trigger: ['blur', 'change'],
                    }
                ],
                oldPassword: [
                    {
                        required: true,
                        validator: checkOldPwd,
                        trigger: ['blur', 'change'],
                    }
                ]
            },
            phoneRules:{
                phone:[
                    {
                        required: true,
                        validator: checkPhone,
                        trigger: ['blur', 'change']
                    }
                ],
                newPassword: [
                    {
                        required: true,
                        validator: checkPassword,
                        trigger: ['blur', 'change'],
                    }
                ],
                affirmPassword:[
                    {
                        required: true,
                        validator: affirmPassword,
                        trigger: ['blur', 'change'],
                    }
                ],
                numCode:[
                    {
                        required: true,
                        trigger:['change','blur'],
                        validator: numCode,
                    }
                ],
                code: [
                    {
                        required: true,
                        message: '请输入验证码'
                    }
                ]
            },
            oldPwdStatus:'1',//通过密码修改  旧密码是否正确  1正确 2错误
            codeUrl:"",
            paramDate:"",
            changeTimer:null,
            changeCount:60,
            inputType: 'password',
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            showPhoneNewPassword: false,
            showPhoneConfirmPassword: false,
        }
    },
    props: {
        phone:{
            type:String
        }
    },
    mounted(){
        this.init();
    },
    computed: {
        //判断是否禁用提交按钮
        disableSubmit(){
            let o = this.passForm;
            if(this.currentIndex == 0){
                return !o.newPassword || !o.oldPassword || !o.affirmPassword;
            }
            o = this.phoneForm;
            return !o.phone || !o.code || !o.newPassword || !o.affirmPassword
        },
        ...mapState( {
            userId: state => state.userId,
            userName: state => state.userName,
            loginName: state => state.loginName,
            schoolId: state => state.schoolId,
        } ),
        classes() {
            return [{'send-color': this.changeTimer === null}]
        },
        closeView () {
            return require('@/assets/images/login/close-view.png')
        },
        showView () {
            return require('@/assets/images/login/show-view.png')
        }
    },
    watch:{

    },
    methods:{
        init(){
            this.referhCode();
            this.phoneForm.phone = this.phone;
        },
       /**
        * @Description 提交
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 邵澳
        * @Date 2023/08/28 15:07
        **/
        async handleSubmit() {
            let res = {}
            if(this.currentIndex == 0){//通过密码修改
                res = await this.changePwdByPwd();
                this.oldPwdStatus = res.data.code;
                this.$refs.pwdForm.validateField("oldPassword");
                this.oldPwdStatus = '1'
            }else{//通过手机号修改
                res = await this.changePwdByPhone();
            }
            if(res.data.code == '200'){
                this.$emit('handleSubmit')
            }
        },
        /**
        * @Description 关闭弹窗
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 韩熙昊
        * @Date 2022/12/14 15:07
        **/
        handleClose() {
            this.$refs.form.clearValidate()
            this.$emit('handleClose')
        },
        changeTab(index){
            this.currentIndex = index;
            this.$nextTick(() =>{
                if(index == 0){
                    this.$refs.pwdForm.clearValidate();
                }else{
                    this.$refs.phoneForm.clearValidate();
                }
                this.$forceUpdate();
            })

        },
        changePwdByPwd(){
            let data = this.passForm;
            console.log(data,'data-data')
            if (data.affirmPassword !== data.newPassword) {
                this.$message.error('两次密码输入不一致，请重新输入。')
                return;
            }

            return new Promise((resolve) => {
                this._fet('/school/schoolUser/modifyPassword', {
                    oldPassword: Encrypt(data.oldPassword),
                    newPassword: Encrypt(data.newPassword),
                    affirmPassword: Encrypt(data.affirmPassword),
                    id: this.userId,
                    loginName: this.loginName
                }).then((res) => {
                    resolve(res)
                })
            })
        },
        changePwdByPhone(){
            return new Promise(resolve => {
                let form = Object.assign({}, this.phoneForm,
                    { newPassword: Encrypt(this.phoneForm.newPassword) },
                    { schoolId: this.schoolId }
                )
                console.log('通过手机号修改密码-----',form);
                this._fet('/school/schoolUser/modifyPasswordByCode', form).then((res) => {
                    resolve(res)
                })
            })

        },
        // 刷新验证码图片
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        useVerify(){
            this.$refs.verify.show()
        },
        success(params){
            console.log('返回的二次验证参数', params)
            this.phoneForm.captchaVerification = params.captchaVerification
            this.getChangePasswordCode()
            // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        },
        getChangePasswordCode(){
            let flag = false;
            this.$refs.phoneForm.validateField(['numCode','phone'],(valid,rule)=>{
                if(valid){
                    flag = true
                }
            })
            if(flag){
                return
            }
            this._fet('/school/schoolAppCommon/sendSms', {
                mobile: this.phoneForm.phone,
                smsType: 2,
                code:this.phoneForm.numCode,
                paramDate: `${this.paramDate}`
            }).then((res) => {
                if (['200','400'].includes(res.data.data.code)) {
                    res.data.data.code == '200' && this.$message.success("提交成功，验证码已发送")
                    if (!this.changeTimer) {
                        const TIME_COUNT = parseInt(res.data.data.time)
                        this.changeCount = TIME_COUNT;
                        this.changeTimer = setInterval(() => {
                            if (this.changeCount > 0 && this.changeCount <= TIME_COUNT) {
                                this.changeCount--;
                            } else {
                                clearInterval(this.changeTimer);
                                this.changeTimer = null;
                            }
                        }, 1000)
                    }
                } else{
                    this.$message.error(res.data.data.info)
                }
            })
        },
        /**
         * @Description: 显示原始密码
         * @Author: 武东超
         * @Date: 2024-08-26 17:00:39
         */        
        switchPassword () {
            this.showPassword = !this.showPassword;
        },
        /**
         * @Description: 显示新密码
         * @Author: 武东超
         * @Date: 2024-08-29 10:29:39
         */        
        switchNewPassword() {
            this.showNewPassword = !this.showNewPassword;
        },
        /**
         * @Description: 显示确认密码
         * @Author: 武东超
         * @Date: 2024-08-29 10:30:19
         */        
        switchConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        /**
         * @Description: 显示手机新密码
         * @Author: 武东超
         * @Date: 2024-08-29 10:38:24
         */        
        switchPhoneNewPassword() {
            this.showPhoneNewPassword = !this.showPhoneNewPassword;
        },
        /**
         * @Description: 显示手机新确认密码
         * @Author: 武东超
         * @Date: 2024-08-29 10:38:55
         */  
        switchPhoneConfirmPassword() {
            this.showPhoneConfirmPassword = !this.showPhoneConfirmPassword;
        }      
    }
}
</script>
<style lang="scss">
    .submit-btn{
        margin-top: 12px;
        width: 300px;
        height: 36px;
        line-height: 1.3;
    }

    .tabs-box{
        position: relative;
        top: -5px;
        width: 350px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .buttons{
            color: #333333;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            &.current{
                color: #3C7FFF;
                .bottom-line{
                    opacity: 1;
                }
            }
            .bottom-line{
                margin: 7px auto 0;
                width:41px;
                height: 6px;
                background-color: #3C7FFF;
                border-radius: 3px;
                opacity: 0;
            }
        }
        .line{
            width: 1px;
            height: 16px;
            border-left: 1px dashed #D8D8D8;

        }
    }
    .form-box{
        margin-top: 20px;
        .input-icon {
            width: 16px;
            padding-top: 9px;
            padding-right: 4px;
            cursor: pointer;
        }
        .tips{
            color: #999999;
            font-size: 14px;
            line-height: 21px;
        }
        .el-empty{
            padding-top: 0 !important;
            .el-empty__description{
                margin: 0;
                p{
                    color: red;
                }
            }
        }
    }
    .codeItem {
        display: flex;
        align-items: center;
        .form-code-img{
            width: 104px;
            height: 32px;
        }
    }
    .send-code-box{
        .el-input-group__append{
            .send-code-btn{
                cursor: pointer;
                &.send-code-btn-disabeld{
                    cursor: not-allowed !important;
                }
            }
        }
        .el-input-group__append{
            width: 105px;
            padding: 0;
            text-align: center;
        }
        .send-color .el-input-group__append{
            background-color:#e6f5eb;
            color: #0a8433;
        }
    }
</style>
