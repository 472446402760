<template>
    <div>
        <el-form ref="bindForm" :model="bindForm" :rules="bindRules" label-width="110px">
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="bindForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>

            <!-- <el-form-item prop="pictureCode" label='数字验证码'>
                <div class="codeItem">
                    <el-input style="width:328px;" v-model="bindForm.pictureCode" placeholder="请输入数字验证码" @keyup.enter.native="handleSubmitBindPhone"></el-input>
                    <img :src="codeUrl" class="form-code-img" @click="referhCode" alt="" />
                </div>
            </el-form-item> -->

            <el-form-item label="验证码" prop="code">
                <div class="send-code-box">
                    <el-input v-model="bindForm.code" placeholder="请输入短信验证码">
                        <span slot="append" class="send-code-btn" v-if="changeTimer === null" @click="useVerify">发送验证码</span>
                        <span slot="append" class="send-code-btn send-code-btn-disabeld" v-else>{{ changeCount }}秒后重新获取</span>
                    </el-input>
                </div>
            </el-form-item>


            <div style="text-align: center">
                <el-button type="primary" class='submit-btn' @click="handleSubmitBindPhone">
                    {{ bindPhoneStatus == 'bind' ?  '确定' :  '解绑' }}
                </el-button>
            </div>
        </el-form>
        <Verify
            @success="success"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>
    </div>
</template>
<script>
import { debounce } from 'common-local';
import { serviceDownload } from "@/services/index.js";
import { Encrypt } from '@/libs/secret'
import { mapState } from "vuex";
import Verify from "../../verifition/Verify";
export default {
    name: "BindPhone",
    components: {
		Verify
	},
    props: {
        bindPhoneStatus: {
            type: String,
            default: () => {
                return 'bind'
            }
        },
        label: String,
    },
    data(){
        const validatePassPhone = (rule, value, callback) => {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (value == null || String(value).trim() === "") {
                    callback(new Error("请输入手机号"));
                } else if (!reg.test(value)) {
                    callback(new Error("手机号格式不正确"));
                } else {
                    callback();
                }
            }
        const validateCOde = (rule, value, callback) => {
            console.log(value,'valuevalue==')
                if(value == '') {
                    return callback(new Error('请输入短信验证码'))
                }
                switch(this.codeStatus) {
                    case "200":
                    case "":
                        return callback();
                        break;
                    case '20012':
                        return callback(new Error('短信验证码错误'))
                        break;
                    default:
                        return callback(new Error('短信验证码错误'))
                }
            }
        const validateNumcode = (rule, value, callback) => {
                if(value == '' || !value) {
                    return callback(new Error('请输入数字验证码'))
                }
                if (value === 'undefined') {
                    return callback(new Error('请输入数字验证码'))
                }
                switch(this.codeStatus) {
                    case "":
                    case "200":
                        return callback();
                        break;
                    case '20012':
                        return callback(new Error('数字验证码错误'));
                        break;
                }
            }
        return{
            bindForm: {
                schoolId: '',
                password: '',
                phone: '',
                code: '',
                captchaVerification: ''
            },
            bindRules: {
                code: [
                    {
                        required: true,
                        validator: validateCOde,
                        trigger: ['blur', 'change']
                    }
                ],
                phone: [
                    {
                        validator: validatePassPhone,
                        required: true,
                        trigger: ['blur', 'change']
                    }
                ],
                // pictureCode: [
                //     {
                //         validator: validateNumcode,
                //         required: true,
                //         trigger: ['blur', 'change']
                //     }
                // ]
            },
            changeLoading:false,
            changeCount:60,
            changeTimer:null,
            codeImg:'',
            codeUrl:'',
            paramDate:'',
            codeStatus:''
        }
    },
    created(){
        this.initPageData()
    },
    activated(){
        // this.init()
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            phone: state => state.phone,
        }),
    },
    methods:{
        initPageData () {
            this.bindForm.phone = this.phone;
        },
        init(){
            this.referhCode();
        },
        resetFields () {
            setTimeout(() => {
                this.$refs.bindForm.resetFields();
            })
        },
       /**
        * @Description 绑定手机号
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 韩熙昊
        * @Date 2022/12/14 15:07
        **/
        handleSubmitBindPhone() {
            const _this = this;
            this.$refs.bindForm.validate((val) => {
                if (val) {
                    let form = Object.assign({}, this.bindForm,
                        { password: Encrypt(this.bindForm.password) },
                        { schoolId: _this.schoolId }
                    )
                    delete form.pictureCode

                    if (this.bindPhoneStatus !== 'bind') {
                        this.$emit('unbindPhone', form)
                        return;
                    }


                    this._fet('/school/schoolUser/bindPhone', form).then((res) => {
                        this.codeStatus = res.data.code;
                        // this.$refs.bindForm.validateField('code');
                        // this.codeStatus = '';
                        if (res.data.code === '200') {
                            // 修改手机号之后，重新调用getUserInfo，刷新保存的个人信息
                            this.$store.dispatch('getUserInfo');
                            this.$refs.bindForm.resetFields();
                            this.handleBindClose();
                            this.$message.success('绑定手机号成功')
                        } else {
                            if(res.data.code == '60002'){//短信验证码错误
                                this.$refs.bindForm.validateField('code');
                            }
                            this.$message.error(res.data.msg)
                        }
                        this.codeStatus = '';
                    })
                }
            })
        },
        /**
        * @Description 关闭绑定手机号弹窗
        * @DoWhat
        * @UseScenarios
        * @Attention 无
        * @Author 韩熙昊
        * @Date 2022/12/14 15:07
        **/
        handleBindClose() {
            this.$refs.bindForm.clearValidate()
            this.$emit('handleBindClose')
        },
        // 刷新验证码图片
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        useVerify(){
            this.$refs.verify.show()
        },
        success(params){
            console.log('返回的二次验证参数', params)
            this.bindForm.captchaVerification = params.captchaVerification
            this.getChangePasswordCode()
            // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        },
        getChangePasswordCode:debounce(function(){
            let flag = false;
            this.$refs.bindForm.validateField(['pictureCode','phone'],(valid,rule)=>{
                if(valid){
                    flag = true
                }
            });
            if(flag){
                return
            }
            this._fet('/school/schoolAppCommon/sendSms', {
                mobile: this.bindForm.phone,
                smsType: 2,
                code:this.bindForm.pictureCode,
                paramDate: `${this.paramDate}`,
                captchaVerification:this.bindForm.captchaVerification
            }).then((res) => {
                if (res.data.data.code === '200') {
                    this.$message.success("提交成功，验证码已发送")
                    if (!this.changeTimer) {
                        const TIME_COUNT = parseInt(res.data.data.time)
                        this.changeCount = TIME_COUNT;
                        this.changeTimer = setInterval(() => {
                            if (this.changeCount > 0 && this.changeCount <= TIME_COUNT) {
                                this.changeCount--;
                            } else {
                                clearInterval(this.changeTimer);
                                this.changeTimer = null;
                            }
                        }, 1000)
                    }
                } else {
                    console.log(res.data.msg,'res.data.msg')
                    this.getChangePasswordCode();
                    this.$message.error(res.data.msg)
                    this.codeStatus = res.data.code;
                    this.$refs.bindForm.validateField('pictureCode');
                    setTimeout(()=>{
                        this.codeStatus = '200'
                    },1000)
                }
            }).catch(err => {

            })
        },1000, true)
    }
}
</script>
<style lang="scss">
    .submit-btn{
        width: 300px;
        height: 36px;
        line-height: 1.3;
    }
    .codeItem {
        display: flex;
        align-items: center;
        .form-code-img{
            width: 104px;
            height: 32px;
        }
    }
    .send-code-box{
        .el-input-group__append{
            .send-code-btn{
                cursor: pointer;
                &.send-code-btn-disabeld{
                    cursor: not-allowed !important;
                }
            }
        }
        .el-input-group__append{
            width: 105px;
            padding: 0;
            text-align: center;
        }
    }

</style>
