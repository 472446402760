<template>
    <div>
        <el-form-item :label="label">
            <div class="flex-box">
                <div class="value">{{value}}</div>
                <div class='btn' v-if="btn" @click="handleClick">{{btn}}</div>
            </div>
            
        </el-form-item>
    </div>
</template>
<script>
export default {
    name: "FormDataItem",
    props: {
        label: String,
        value: String,
        btn: String
    },
    methods:{
        handleClick(){
            this.$emit('handleFormItemClick')
        }
    }
}
</script>
<style lang="scss">
    .flex-box{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        .value{
            text-align: left;
            width: 144px;
            color: #333333;
        }
        .btn{
            color: #3C7FFF;
            cursor: pointer;
        }
    }
</style>
